/**
 * Reloads the page if the last reload was more than 5-10s ago.
 * This prevents infinite reload loops when the server has issues.
 * The jitter is added to prevent all users from reloading at once.
 * @throws {Error} - If getting/setting {@link window.localStorage} fails.
 */
export const safeReload = () => {
  const storageKey = "nm-lastReloadTime";
  const thresholdTimeSeconds = 5;
  const lastReloadTime = Number.parseInt(
    localStorage.getItem(storageKey) ?? "0",
    10
  );
  const shouldReload =
    Math.abs((Date.now() - lastReloadTime) / 1000) >
    thresholdTimeSeconds + 5 * Math.random();
  console.log({ shouldReload, lastReloadTime });

  if (!shouldReload) {
    // Try again after some time.
    // setTimeout(safeReload, thresholdTimeSeconds * 1000);
    return console.error(
      "Skipping reloading as it was done recently. Try refreshing manually after some time."
    );
  }

  localStorage.setItem(storageKey, Date.now().toString());
  // If localstorage.setItem throws, we will not reload. This is fine.
  // eslint-disable-next-line no-restricted-properties
  console.log("Safe reloading the page.");
  window.location.reload();
};
